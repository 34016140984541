<template>
  <v-app>
    <ui-drawer />

    <!--    <v-navigation-drawer app right color="white" floating>
      <router-view name="rightDrawer"> </router-view>
    </v-navigation-drawer>-->
    <v-app-bar
      flat
      app
      height="80px"
      outlined
      clipped-left
      style="border-left: none; border-right: none; border-top: none;"
    >
      <router-link class="custom-link" :to="{ name: 'Dashboard' }">
        <img
          v-if="settings.brand_image"
          :src="brandUrl"
          height="50px"
          :alt="settings.name"
          class="mr-10"
        />
      </router-link>
      <div class="hidden-sm-and-down">
        <v-toolbar-title class="text-h5 font-weight-medium">
          {{
            $route.meta.barTitle
              ? $route.meta.barTitle
              : "TaxConsultantServices"
          }}
        </v-toolbar-title>
        <div>
          <span class="primary--text text--darken-3 font-weight-medium">
            {{ $moment().format("dddd") }},
          </span>
          <span class="font-weight-medium grey--text text--darken-2">
            {{ $moment().format("Do MMM YY") }}
          </span>
        </div>
      </div>
      <v-spacer />
      <!--      <v-btn icon class="mr-2">
        <v-icon>mdi-bell-outline</v-icon>
      </v-btn>-->
      <v-avatar class="white--text" color="primary">
        <span v-if="!userDetails.image_url">{{ userDetails.initials }}</span>
        <img v-else :src="userDetails.image_url" alt="Profilbild" />
      </v-avatar>
      <v-menu bottom transition="slide-y-transition" open-on-hover offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">
            {{ userDetails.name_reverse }}
            <v-icon right>
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </template>
        <v-list nav>
          <v-list-item
            link
            :to="{ name: 'UserProfile', params: { id: userDetails.id } }"
          >
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Profil</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Ausloggen</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <router-view name="appBarExtension" />
    </v-app-bar>
    <v-main class="white" style="min-height: 100%;">
      <v-slide-y-transition>
        <v-system-bar
          key="systemBarActive"
          color="#f5f5f5"
          v-if="breadCrumbs && breadCrumbs.length > 0"
          style="position: absolute; width: 100%; min-height: 30px; height: auto;"
        >
          <!-- Breadcrumbs -->
          <v-breadcrumbs :items="breadCrumbs" large class="pa-2">
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                :to="item.to"
                :disabled="item.disabled ? item.disabled : false"
                exact
              >
                {{ item.text }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-system-bar>
        <v-system-bar
          style="position: absolute; width: 100%; min-height: 30px; height: auto;"
          v-else
          key="systemBarInactive"
          color="transparent"
          height="30"
        />
      </v-slide-y-transition>
      <v-container fluid class="px-lg-16 pt-11 mt-2" style="min-height: 100%">
        <v-fade-transition>
          <router-view name="main" />
        </v-fade-transition>
      </v-container>
      <vue-snotify></vue-snotify>
    </v-main>
    <v-footer
      class="px-lg-16"
      dark
      style="position: relative; height: auto; overflow: visible;"
    >
      <div
        class="pl-lg-14 py-4 d-flex justify-space-between align-center"
        style="width: 100%"
      >
        <div>
          <a href="https://institut-ida.de" target="_blank">
            <img
              src="@/assets/ida_lowres.png"
              alt="IDA – Institut für Innovation und Digitalisierung"
              style="max-height: 60px"
            />
          </a>
        </div>
        <div class="text-right">
          <div>
            {{ settings.name }} <br />
            {{ settings.address1 }} <br />
            {{ settings.zip }} {{ settings.city }}
          </div>
          <div class="mt-4" v-if="settings.email">
            {{ settings.email }}
          </div>
          <div v-if="settings.phone">
            {{ settings.phone }}
          </div>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { adjustedColor } from "@/helpers/adjustColor";

export default {
  name: "Wrapper",
  data: () => ({}),
  computed: {
    brandUrl() {
      return this.$store.getters["state/brandUrl"];
    },
    settings() {
      return this.$store.getters["state/settings"];
    },
    userDetails() {
      return this.$store.getters["state/userDetails"];
    },
    breadCrumbs() {
      return this.$store.getters["application/breadCrumbs"];
    }
  },
  created() {
    function LightenDarkenColor(col, amt) {
      let usePound = false;

      if (col[0] === "#") {
        col = col.slice(1);
        usePound = true;
      }

      const num = parseInt(col, 16);

      let r = (num >> 16) + amt;

      if (r > 255) r = 255;
      else if (r < 0) r = 0;

      let b = ((num >> 8) & 0x00ff) + amt;

      if (b > 255) b = 255;
      else if (b < 0) b = 0;

      let g = (num & 0x0000ff) + amt;

      if (g > 255) g = 255;
      else if (g < 0) g = 0;

      return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
    }
    if (this.settings.primary_color) {
      /*this.$vuetify.theme.themes.light.primary = adjustedColor(
        -0.6,
        this.settings.primary_color
      );*/
      this.$vuetify.theme.themes.light.primary = this.settings.primary_color;
      this.$vuetify.theme.themes.light.accent = LightenDarkenColor(
        this.$vuetify.theme.themes.light.primary,
        140
      );
      if (this.settings.secondary_color) {
        this.$vuetify.theme.themes.light.secondary = this.settings.secondary_color;
      }
    }
  },
  methods: {
    logout() {
      this.$store
        .dispatch("state/logout")
        .then(() => {
          this.$router.push({ name: "Home" });
        })
        .catch(() => {
          this.$snotify.error("Abmeldung fehlgeschlagen.");
        });
    }
  }
};
</script>

<style scoped>
.theme--light.v-application {
  background-color: #f5f5f5 !important;
}
.v-application--wrap {
  background-color: #f5f5f5 !important;
}
</style>
